const editorTemplates = [
  {
    title: "1 to 2 Ratio Columns with Mobile Response < 750px",
    description: "Use Shift-Enter for new lines within the Columns, Enter by itself will create a new Column",
    content: `<style>
    .f-cont { display: flex;}
    .f1 {flex: 1}
    .f2 {flex: 2}
    div {border: 1px dashed rgba(0,0,0,0.1);}
    </style>
    <div class="f-cont">
      <div class="f1">LEFT...</div>
      <div class="f2">...RIGHT</div>
    </div>`
  },
  {
    title: "2 to 1 Ratio Columns with Mobile Response < 750px",
    description: "Use Shift-Enter for new lines within the Columns, Enter by itself will create a new Column",
    content: `<style>
    .f-cont { display: flex;}
    .f1 {flex: 1}
    .f2 {flex: 2}
    div {border: 1px dashed rgba(0,0,0,0.1);}
    </style>
    <div class="f-cont">
      <div class="f2">LEFT...</div>
      <div class="f1">...RIGHT</div>
    </div>`
  },
  {
    title: "3 Columns with Mobile Response < 750px",
    description: "Use Shift-Enter for new lines within the Columns, Enter by itself will create a new Column",
    content: `<style>
    .f-cont { display: flex;}
    .f1 {flex: 1}
    .f2 {flex: 2}
    div {border: 1px dashed rgba(0,0,0,0.1);}
    </style>
    <div class="f-cont">
      <div class="f1">LEFT...</div>
      <div class="f1">...Middle...</div>
      <div class="f1">...RIGHT</div>
    </div>`
  }
]
const contentStyle = `p,div,span,a { font-family: "Open Sans"; }
.f-cont { display: flex;}
.f1 {flex: 1}
.f2 {flex: 2}
div {border: 1px dashed rgba(0,0,0,0.1);}
`
const plugins = 'link lists image table template code';
const toolbar = 'styleselect | link image forecolor backcolor bold | alignleft aligncenter alignright alignjustify | template table | numlist bullist';
const content_css = ['https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'];
const font_formats = 'Open Sans=Open Sans';

export default {
  editorTemplates,
  contentStyle,
  plugins,
  toolbar,
  content_css,
  font_formats
}

