<template>
  <PageContent v-if="name">
    <div class="form">
      <SubHeading>Edit: {{name}}</SubHeading>
      <div class="links">
        <div>
          <router-link :to="{ name: 'Articles' }">Back to Articles...</router-link>
        </div>
        <AdminBreadCrumb />
      </div>
      <br>
      <textarea v-html="html" id="editor"></textarea>

      <div class="buttonRow">
        <button @click="viewImages = !viewImages">Insert Image</button>
        <div class="f1"></div>
        <div class="hidden success" :class="{not: displayMessage}">{{message}}</div>
        <button @click="SaveArticle">Save</button>
      </div>

      <Modal :open="viewImages" @close="viewImages = !viewImages">
        <template #header>Image Management</template>
        <template #body> 
          <div class="fileUpload">
            <span>Would you like to upload a new image: </span>
            <FileUpload type="image" @file-uploaded="ImageLoaded" />
          </div>
          <ImageList :images="images" @image-clicked="InsertImage" v-if="images.length" ></ImageList>
        </template>
      </Modal>
    </div>
  </PageContent>
</template>

<style scoped>
  .form {
    display: flex;
    flex-direction: column;
  }
  .links {
    display: flex;
    justify-content: space-between;
  }
  .hidden {
    opacity: 0;
    transition: opacity 1s;
    margin-top: 20px;
  }
  .hidden.not {
    opacity: 1;
  }
  .success {
    color: darkgreen;
  }
  button {
    font-size: 1.6rem;
    color: white;
    background-color:darkgreen;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    transition: background-color 1s;
    margin-left: 5px;
  }
  button:focus {
    outline:0;
  }
  button:hover {
    background-color: green;
  }
  .buttonRow {
    display: flex;
    align-content: flex-end;
  }
  .fileUpload {
    display: flex;
    padding: 20px;
    background-color: aliceblue;
    border-radius: 20px;
    margin-top: 10px;
    justify-content: center;
  }
  .fileUpload span {
    margin-right: 20px;
  }
</style>

<script>
import PageContent from "@/components/PageContent";
import SubHeading from "@/components/SubHeading";
import ImageList from "@/components/ImageList";
import FileUpload from "@/components/FileUpload";
import AdminBreadCrumb from "@/components/Admin/AdminBreadCrumb";
import Modal from "@/components/Modal";

import ImageService from "@/services/ImageService";
import ArticleService from "@/services/ArticleService";

import EdConfig from '@/config/editor.js'

export default {
  name: "Article",
  components: {
    PageContent,
    SubHeading,
    ImageList,
    FileUpload,
    Modal,
    AdminBreadCrumb
  },
  data() {
    return {
      html: "Content Loading...",
      name: "",
      editor: null,
      displayMessage: false,
      message: "",
      images: [],
      viewImages: false
    };
  },
  created() {},
  async mounted() {
    this.name = this.$route.params.name;
    this.loadHtml();
    await this.LoadImages();
    setTimeout(() => {
      let editorScript = document.createElement('script')
      editorScript.setAttribute('src', 'https://cdn.tiny.cloud/1/hs0rm2z4y8deq9ag63nazjwwbewmi6i3divj3ksn8k7kzhiv/tinymce/5/tinymce.min.js')
      editorScript.async = true;
      editorScript.referrerpolicy="origin";
      editorScript.onload = () => {
        window.tinymce.init({
          selector: 'textarea#editor',
          plugins: EdConfig.plugins,
          toolbar: EdConfig.toolbar,
          content_css: EdConfig.content_css,
          font_formats: EdConfig.font_formats,
          templates: EdConfig.editorTemplates,
          content_style: EdConfig.contentStyle
        });
      }
      document.head.appendChild(editorScript)
    }, 1000);
  },
  methods: {
    async loadHtml() {
      this.html = await ArticleService.getArticle(this.name);
    },
    async SaveArticle() {
      let content = window.tinymce.activeEditor.getContent();
      this.message = "Saved";
      this.displayMessage = true;
      await ArticleService.saveArticle(this.name, content);
      setTimeout(() => {this.displayMessage = false;}, 2000);
    },

    async LoadImages() {
      this.images = await ImageService.LoadImages()
    },
    InsertImage(value) {
      this.insertHTML(`<img src="/images/${value.name}" alt="${value.name}"/>`);
      this.viewImages = false;
    },
    insertHTML(html) {
      window.tinymce.activeEditor.execCommand('mceInsertContent', false, html);
    },
    async ImageLoaded() {
      await this.LoadImages();
      alert("Image Uploaded");
    }
  }
};
</script>
